import {
  EnvironmentSettings,
  EnvironmentType,
} from '@skyfit/core-data-library';

export const baseDevelopEnvironment: EnvironmentSettings = {
  aaptivServiceUrl: 'https://skyfit.aapdev.com',
  productionMode: false,
  repTime: 4,
  sentryDsn:
    'https://dc1bfd4d72e948b1928605beb326c1ea@o367782.ingest.sentry.io/6724194',
  serverEnvironment: EnvironmentType.development,
  googleAnalyticsTrackingId: 'G-42V0BEE57Z',
};
