import { baseDevelopEnvironment } from '@environments/_base/develop.environment';
import { TVEnvironmentSettings } from '../tv-environment-settings';

export const environment: TVEnvironmentSettings = {
  ...baseDevelopEnvironment,
  firebase: {
    apiKey: 'AIzaSyBAziCwNww7WGFXh2hvQLD38B-Nr4QAYNI',
    authDomain: 'pearsports-develop-291715.firebaseapp.com',
    projectId: 'pearsports-develop-291715',
    storageBucket: 'pearsports-develop-291715.appspot.com',
    messagingSenderId: '1018643125835',
    appId: '1:1018643125835:web:a7a44db15ac03111661fce',
    measurementId: 'G-X5GE93M9TV',
  },
  pearTVConfig: {
    pearProUrl: 'admin-develop.aapdev.com',
    appHostname: 'localhost',
  },
  datadog: {
    clientToken: 'pubba37f36c70fe2f3995ff50ab4eb2f8ee',
  },
};
